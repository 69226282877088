/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                // Swipe functionality
                $(".slider-viewable").swipe({
                    allowPageScroll: 'vertical',
                    //Generic swipe handler for all directions
                    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                        if (direction === 'left') {
                            next();
                        } else if (direction === 'right') {
                            previous();
                        }
                    },
                    //Default is 75px, set to 0 for demo so any distance triggers swipe
                    threshold: 10
                });

                // Enable fancybox
                $('.fancybox').fancybox({
                    maxWidth: 800,
                    maxHeight: 600,
                    fitToView: false,
                    width: '70%',
                    height: '70%',
                    autoSize: false,
                    closeClick: false,
                    openEffect: 'none',
                    closeEffect: 'none'
                });

                var sliding = false; // Is the Year slider being moved?
                var yearsList = $('#slider-years-list'); // Year slider container
                var years = yearsList.children('li'); // All years in the container
                var length = (years.length * 63); // Total length of all years
                // Total width of the years container that does not fit on screen
                var scrollable = length - $('#slider-years-container').width();
                // Dist. to move for every 1 unit moved on slider
                var unit = scrollable / 100;
                // Set the distance that will move the slideshow
                var slideAtDistance = 100 / (years.length / 2);
                var onePanePercent = 63 * 2 / length * 100;

                // Dynamically set the length of the year slider
                yearsList.css('width', length);

                /* Year Slider */
                $(document).on('mousedown', '.ui-slider-handle', function() {
                    sliding = true;
                });

                $(document).on('mouseup', function() {
                    sliding = false;
                });

                /* Event Slider */
                var $slider = $('#slider-box').find('div.slider'), // Get the slider pane holder
                    $total = $slider.find('li').length,
                    $paneLength = 1048,
                    $currentPane = 0;

                var scrollToPage = function(page) {

                    if (page !== $currentPane && page < $total) {

                        close_popup();

                        var shouldBeAtDistance = -(page * $paneLength);
                        $slider.stop().animate({
                            left: shouldBeAtDistance
                        }, 1000, function() {
                            //alert('done');
                        });

                        $currentPane = page;
                    }
                };

                var next = function() {
                    if ($currentPane + 1 < $total) {
                        slide(-$paneLength);
                        $currentPane = $currentPane + 1;
                        var value = onePanePercent + $('#slider-tabs-container').slider("option", "value");
                        $('#slider-tabs-container').slider('value', value);
                        animateYears(value);
                    }
                };

                var previous = function() {
                    if ($currentPane + 1 > 1) {
                        slide($paneLength);
                        $currentPane = $currentPane - 1;
                        var value =  $('#slider-tabs-container').slider("option", "value") - onePanePercent;
                        $('#slider-tabs-container').slider('value', value);
                        animateYears(value);
                    }
                };

                var slide = function(distance) {
                    close_popup();
                    $slider.animate({
                        left: "+=" + distance
                    }, 1000, function() {
                        //alert('done');
                    });
                };

                var animateYears = function(value) {
                    yearsList.animate({
                        left: -(value * unit)
                    }, 500, function() {
                        //alert('done');
                    });
                };

                // Get the year to slide to if one exists
                var startPost = $('#start_post').val();
                // Get how many panes should be shown
                var numberPosts = $('#number_posts').val();

                var startRange = 0;
                var endRange = 100;
                var startValue = 0;

                // Get the position of the Start Post
                if (startPost) {
                    var current = years.filter('#year-' + startPost),
                        index = years.index(current),
                        endIndex = parseInt(numberPosts) * 2 - 1 + parseInt(index),
                        startRange = ((index * 63) / length) * 100,
                        startValue = ((index * 63) / length) * 100;

                    // Get 1 more index so the actual last index will be affected
                    var end = $(years).eq(endIndex + 1),
                        endRange = (((endIndex + 1) * 63) / length) * 100;

                    $(years).not($(current)
                        .nextUntil(end, "li")
                        .andSelf()).addClass("disabled");
                }

                $('#slider-tabs-container').slider({
                    animate: 'slow',
                    step: 0.25,
                    value: startValue,
                    create: function(event, ui) {
                        animateYears($(this).slider('value'));
                    },
                    slide: function(event, ui) {
                        if (ui.value < startRange || ui.value > endRange) {
                            return false;
                        }
                        var page = Math.floor((ui.value - startValue) / slideAtDistance);

                        scrollToPage(page);

                        if (sliding) {
                            yearsList.css('left', -(ui.value * unit));
                        } else {
                            animateYears(ui.value);
                        }
                    }
                });

                $(document).on('click', 'a.advance', function(e) {
                    e.preventDefault();
                    var btn = $(this);
                    if (btn.hasClass('previous')) {
                        previous();
                    } else if (btn.hasClass('next')) {
                        next();
                    }
                });

                /* Pillars Slider */
                var container = $('#pillars-content');
                var arrows = $('#pillars-show').find('.arrow');

                $('#pillars-show').click(function() {
                    if (container.is(":visible")) {
                        // Hide - slide up.
                        container.slideUp(1000, function() {
                            arrows.removeClass('down');
                            arrows.addClass('up');
                        });
                    } else {
                        // Show - slide down.
                        container.slideDown(1000, function() {
                            arrows.removeClass('up');
                            arrows.addClass('down');
                        });
                    }
                });

                $('div.pane-box:has(a.magnify), div.pane-box:has(a.video)').css('cursor', 'pointer');

                /* Popups */
                $(document).on('click', 'div.pane-box:has(a.magnify), div.pane-box:has(a.video), a.ghost', function(e) {

                    e.preventDefault();
                    var button = $(this);

                    if (button.hasClass('pane-box')) {
                        var post_id = button.find('a').attr('href');
                    } else {
                        var post_id = button.attr('href');
                    }

                    var data = {
                        action: 'my_action',
                        post_id: post_id
                    };

                    $.post(ajaxurl, data, function(response) {
                        var result = $.parseJSON(response);
                        if (!result.error) {
                            var post = result.result;
                            var popup = $('#popup_box');

                            if (button.hasClass('ghost')) {
                                $('#popup_image').hide();
                                $('#popup_content_div').removeClass('col-md-6').addClass('col-md-12');
                            } else {
                                $('#popup_image').show();
                                $('#popup_image').html(post.featured_image);
                                $('#popup_content_div').removeClass('col-md-12').addClass('col-md-6');
                            }

                            $('#popup_column').addClass(post.column_color);
                            $('#popup_subtitle').text(post.sub_title);
                            $('#popup_title').addClass(post.column_color).text(post.post_title);
                            $('#popup_content').html(post.post_content);
                            $('#slider-ul').animate({
                                opacity: 0.1
                            }, 'slow');
                            $('#popup_box').fadeIn("slow");
                        }
                    });
                });

                var close_popup = function() {
                    $('#slider-ul').animate({
                        opacity: 1
                    }, 'slow');
                    $("#popup_box").fadeOut("slow", function() {
                        $('#popup_column').removeClass("blue brown green grey orange red purple");
                        $('#popup_title').removeClass("blue brown green grey orange red purple");
                    });
                };

                $(document).on('click', 'a.close_popup', function(e) {
                    e.preventDefault();
                    close_popup();
                });

                /**
                 * Mobile Functions
                 */
                $('#mobile-markers').on('click', function(e) {
                    e.preventDefault();
                    $('.purple-menu').hide();
                    $('.content-info').hide();
                    $('#mobile-pillars').show();
                });

                $('#mobile-pillars .close_popup').on('click', function(e) {
                    e.preventDefault();
                    $('.purple-menu').show();
                    $('.content-info').show();
                    $('#mobile-pillars').hide();
                });

                $('#mobile-timeline').on('click', function(e) {
                    e.preventDefault();
                    $('.purple-menu').hide();
                    $('.content-info').hide();
                    $('#mobile-periods').show();
                });

                $('#mobile-periods .close_popup').on('click', function(e) {
                    e.preventDefault();
                    $('.purple-menu').show();
                    $('.content-info').show();
                    $('#mobile-periods').hide();
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                // Fade in the View Timeline button
                $('a.view-timeline').delay(5000).fadeIn('slow');

                var video = $("#bgvid");

                video.bind('ended', function() {
                    video.get(0).pause();
                });

                $(".wrapper").on("click", function(e) {
                    e.preventDefault();
                    if (!$(e.target).hasClass('push')) {
                        playPauseVideo();
                    }
                });

                var playPauseVideo = function() {
                    var btn = $("#pauseplay");
                    if (btn.hasClass('play')) {
                        video.get(0).play();
                    } else {
                        video.get(0).pause();
                    }
                    btn.toggleClass('play');
                };
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
